<template>
  <div class="change-password-container">
    <el-form
      ref="changePasswordForm"
      :model="form"
      :rules="passwordRules"
      label-width="200px"
    >
      <el-form-item prop="password" label="Current Password">
        <el-input show-password v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item
        prop="newPassword"
        :inline-message="true"
        label="New Password"
      >
        <el-input show-password v-model="form.newPassword"></el-input>
      </el-form-item>
      <el-form-item prop="newConfirmPassword" label="Confirm your new Password">
        <el-input show-password v-model="form.newConfirmPassword"></el-input>
      </el-form-item>
      <div class="buttons">
        <el-button type="primary" @click="resetPassword">
          Change Password
        </el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { getData, putData } from "../../service/api";

export default {
  data() {
    return {
      controllerUrl: "/student",
      query: {
        studentId: null,
      },
      form: {
        password: null,
        newPassword: null,
        newConfirmPassword: null,
      },
      passwordRuleForm: {},
      passwordRules: {
        password: [
          {
            required: true,
            message: "Please input your current password",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "Please input your new password.",
            trigger: "blur",
          },
        ],
        newConfirmPassword: [
          {
            required: true,
            message: "Please confirm your new password.",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (this.form.newPassword !== value) {
                callback(
                  new Error(
                    "the confirmed password is not matched with the new password."
                  )
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    let studentId = this.$user.getUsername();
    console.log("ChangePassword.created", studentId);
    if (studentId) {
      this.query.studentId = studentId;
      this.loadPasswordRule();
    }
  },
  methods: {
    loadPasswordRule() {
      getData("/common/loadPasswordRule/student").then((res) => {
        if (res.result && res.code === "200") {
          this.passwordRuleForm = res.result;
          this.passwordRules.newPassword.push({
            pattern: new RegExp(this.passwordRuleForm.pattern),
            message: this.passwordRuleForm.description,
          });
        } else {
          this.$message.error(
            `Loading password rule failed, error message: ${res.message}`
          );
        }
      });
    },
    resetPassword() {
      this.$refs.changePasswordForm.validate((valid) => {
        if (valid) {
          putData(
            `/student/reset_password`,
            this.query.studentId,
            this.form
          ).then((res) => {
            if (res.result && res.code === "200") {
              this.form = {};
              this.$message({
                message: "Password reset successfully.",
                type: "info",
              });
            } else {
              this.$message({
                message: res.message,
                type: "warning",
              });
            }
          });
        } else {
          this.$message.error("Please correct the input error!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.change-password-container {
  padding: 30px;
}
.change-password-container .buttons {
  text-align: center;
}
</style>
